import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import renderAst from '../render-ast';
import { ReferenceContext } from '../components/reference/reference';
import './blog-post.scss';

(async () => {
  await import('./katex.scss');
})();

const MainHeader = ({ title, date, moustache }) =>
  title && (
    <hgroup>
      <h1>{title}</h1>
      {date && (
        <h2>
          {date}
          {moustache && ` — ${moustache}`}
        </h2>
      )}{' '}
    </hgroup>
  );

function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, htmlAst } = markdownRemark;
  const references = useRef(new Map());
  const count = useRef(0);
  const referenceContextValue = { references, count };
  return (
    <Layout>
      <SEO title={frontmatter.title} keywords={['Simplified Development', 'Performance']} />
      <ReferenceContext.Provider value={referenceContextValue}>
        <article className="blog-post">
          {!frontmatter.hideTitle && <MainHeader {...frontmatter} />}
          {renderAst(htmlAst)}
        </article>
      </ReferenceContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        path
        title
        hideTitle
        moustache
      }
    }
  }
`;

export default Template;
