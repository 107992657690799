import React, { useRef, useEffect, useState } from 'react';
import './perf-demo.scss';

const ANIMATION_TIME = 1000;
const green = '#0f9d58';
const yellow = '#ffa800';
const red = '#b30000';

const PerfDemo = () => {
  const ball = useRef();
  const [fps, setFps] = useState(100);

  useEffect(() => {
    if (fps === 100) return () => {};
    const ratio = (60 - fps) / 60;
    const start = Date.now();
    let now = start;
    let raf;

    function animate() {
      raf = requestAnimationFrame(animate);
      if (!ball.current) return;
      now = Date.now();
      const t = now - start;
      const x = (t / ANIMATION_TIME) % 1;
      const isReversed = (t / ANIMATION_TIME) % 2 > 1;
      if (x >= ratio + 0.02 || x < 0.02) {
        if (isReversed) {
          ball.current.style.left = `calc(calc(${1 -
            x} * calc(100% - 60px)) + 30px)`;
        } else {
          ball.current.style.left = `calc(calc(${x} * calc(100% - 60px)) + 30px)`;
        }
      }
    }

    raf = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(raf);
  }, [fps]);

  const style = {
    backgroundColor: fps > 40 ? green : fps > 20 ? yellow : red
  };

  return (
    <div className="perf-demo">
      <div className="perf-demo-btns">
        <button onClick={() => setFps(60)}>60 FPS</button>
        <button onClick={() => setFps(30)}>30 FPS</button>
        <button onClick={() => setFps(10)}>10 FPS</button>
        <button onClick={() => setFps(100)}>stop</button>
      </div>
      <div className="perf-demo-bounce">
        <div className="perf-demo-bounce__ball" ref={ball} style={style} />
      </div>
    </div>
  );
};

export default PerfDemo;
