import React from 'react';

const scrollTop = () => {
  // When the user clicks on the button, scroll to the top of the document
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const BackToTop = (props) => (
  <button onClick={scrollTop}>Back to top</button>
);

export default BackToTop;
