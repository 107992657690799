import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Avatar = ({ name }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "images/me.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 300)
          }
        }
      }
    `}
    render={data => (
      <figure>
        <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} Tag="div" />
      </figure>
    )}
  />
);
export default Avatar;
