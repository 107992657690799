import React, { useContext } from 'react';
import './reference.scss';

export const ReferenceContext = React.createContext({
  references: new Map(),
  count: { current: 0 }
});

const symbols = ['*', '†', '‡', '§', '¶', '#'];

const useReferenceSymbol = id => {
  const { references, count } = useContext(ReferenceContext);

  if (!references.current.has(id)) {
    references.current.set(id, count.current);
    count.current += 1;
  }

  const symbolId = references.current.get(id);
  const symbol = symbols[symbolId % 5];
  const multiplier = Math.floor(symbolId / 5) + 1;
  return symbol.repeat(multiplier);
};

export const Reference = ({ id }) => {
  const symbol = useReferenceSymbol(id);

  return (
    <sup className="ref" id={`ref-${id}`}>
      <a href={`#${id}`}>({symbol})</a>
    </sup>
  );
};

export const ReferenceContent = ({ id, children }) => {
  const symbol = useReferenceSymbol(id);

  return (
    <div className="ref" id={id}>
      <div className="ref__symbol">
        <a href={`#ref-${id}`}>{symbol}</a>
      </div>
      <div className="ref__content">{children}</div>
    </div>
  );
};

export const ReferenceGoBack = ({ id }) => (
  <a className="ref-go-back" href={`#ref-${id}`}>
    (Go back)
  </a>
);
