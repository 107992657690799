import React from 'react';
import Avatar from './avatar';
import './me.scss';

const Me = props => {
  return (
    <div className="me">
      <Avatar />
      <div className="text">
        Hey! I’m CP Clermont (
        <a href="//twitter.com/cpclermont">@cpclermont</a>), a dad, husband, and Web Performance Engineer from Prévost, a small town north of Montréal, QC, Canada. French is my first language.
      </div>
    </div>
  );
};

export default Me;
