import React from 'react';
import * as R from 'ramda';
import rehypeReact from 'rehype-react';
import PerfDemo from './components/perf-demo/perf-demo';
import {
  Reference,
  ReferenceContent,
  ReferenceGoBack
} from './components/reference/reference';
import BackToTop from './components/back-to-top';
import Me from './components/me';

const components = {
  'back-to-top': BackToTop,
  'go-back': ReferenceGoBack,
  'perf-demo': PerfDemo,
  reference: Reference,
  'reference-content': ReferenceContent,
  me: Me
};

const popOutComponents = ['perf-demo', 'reference-content', 'me'];

const isCustomComponent = R.pipe(
  R.path(['children', 0, 'tagName']),
  R.includes(R.__, popOutComponents)
);

const isImg = R.propEq('tagName', 'img');
const isSource = R.propEq('tagName', 'source');

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components
}).Compiler;

const promoteChild = R.map(child => {
  if (isCustomComponent(child)) return child.children[0];
  return child;
});

const lazyImage = child => {
  if (isSource(child)) {
    return {
      ...child,
      properties: {
        ...R.omit(['srcSet', 'sizes'], child.properties),
        'data-srcset': child.properties.srcSet.join(', '),
        'data-sizes': child.properties.sizes
      }
    };
  } else if (isImg(child)) {
    return {
      ...child,
      properties: {
        ...R.omit(['src', 'loading'], child.properties),
        'data-src': child.properties.src,
        className: (child.properties.className || []).concat('lazyload')
      }
    };
  } else if (child.children) {
    return lazyImages(child);
  }
  return child;
};

const lazyImages = R.evolve({
  children: R.map(lazyImage)
});

const isEmptyPTag = x => x.tagName === 'p' && x.children.length === 0;
const getRidOfEmptyPTags = R.reject(isEmptyPTag);

const promoteMyComponents = R.evolve({
  children: R.pipe(promoteChild, R.map(lazyImages), getRidOfEmptyPTags)
});

export default ast => renderAst(promoteMyComponents(ast));
